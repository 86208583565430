import React, {useState} from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "@react-spring/web";

interface FadeInProps {
  delay?: number;
}

export default function FadeIn({ children, delay = 350 }: React.PropsWithChildren<FadeInProps>) {
  const [inView, setInView] = useState(false);

  const transition = useSpring({
    delay,
    to: {
      y: !inView ? 24 : 0,
      opacity: !inView ? 0 : 1,
    }
  })

  return (
    <Waypoint onEnter={() => setInView(true)}>
      <animated.div style={transition}>
        {children}
      </animated.div>
    </Waypoint>
  )
}